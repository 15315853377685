export class RefElements {

  constructor() {
    this.html = document.querySelector('html');
    this.body = document.querySelector('body');

    this.layout = document.querySelector('.layout');

    this.mobMenu = document.querySelector('.mobile-menu');
    this.header = document.querySelector('.header');
  }
}
